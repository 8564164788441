import axios from "axios";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const print = async (payload, imageId) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/images/${imageId}/print`,
      payload
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/images/print", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};
