// ** MUI Imports
import { Button } from "@mui/joy";

// ** Hooks Imports
import { usePrinting } from "../../hooks/usePrinting";

// ** Third party librarie
import { createModule, useLightboxState } from "yet-another-react-lightbox";
import axios from "axios";

// ** Api Imports
import { print } from "../../../api/printApi";

const printImage = async (imageId) => {
  try {
    const printPayload = {
      userId: "6620f98c038eb468291bb54f",
      printsNumber: 1,
    };

    await print(printPayload, imageId);
  } catch (e) {
    console.log("ERROR printing image:", e);
  }
};

const PrintingButton = ({ children, ...props }) => {
  const { currentSlide } = useLightboxState();
  const [printingQueue] = usePrinting(props.eventId);

  return (
    <>
      {currentSlide.type !== "video" && props.isPrintingEvent && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: "1301",
            bottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "150px",
            }}
            variant="solid"
            color="primary"
            size="md"
            disabled={printingQueue.includes(currentSlide.imageId)}
            loading={printingQueue.includes(currentSlide.imageId)}
            loadingPosition="end"
            onClick={() => {
              printImage(currentSlide.imageId);
            }}
          >
            {printingQueue.includes(currentSlide.imageId)
              ? "Așteaptă"
              : "Print"}
          </Button>
        </div>
      )}
    </>
  );
};

const PrintModule = createModule("PrintButton", PrintingButton);
export const PrintPlugin = ({ addSibling, augment }) => {
  augment(({ eventId, ...restProps }) => ({
    eventId: eventId,
    ...restProps,
  }));

  addSibling("toolbar", PrintModule);
};
