// ** React imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ** MUI Imports
import Key from "@mui/icons-material/Key";
import Visiblity from "@mui/icons-material/Visibility";
import VisiblityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import PageHeader from "../components/PageHeader";
import Button from "@mui/joy/Button";

// ** Hooks iImports
import useQuery from "../hooks/useQuery";

// ** API Imports
import { sendResetPasswordEmail } from "../../api/userApi";

import axios from "axios";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState("initial");
  const [errors, setErrors] = useState({});
  const [visibility, setVisibility] = useState(false);

  const { t } = useTranslation("ResetPassword");
  const { t: te } = useTranslation("Errors");

  const query = useQuery();
  const navigate = useNavigate();

  const token = query.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    if (password !== confirmPassword) {
      setErrors({ confirmPassword: te("passwordMismatch") });
      setStatus("!loading");
      return;
    }

    try {
      const resetPasswordPayload = { password };
      await sendResetPasswordEmail(resetPasswordPayload, token);

      navigate("/admin-login");
    } catch (e) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    if (password === confirmPassword) {
      setErrors({});
    }
  }, [password, confirmPassword]);

  const visibilityIconRenderer = (isVisible) => {
    const Icon = isVisible ? Visiblity : VisiblityOff;

    return (
      <Icon
        sx={{ cursor: "pointer" }}
        onClick={() => setVisibility((prev) => !prev)}
      />
    );
  };

  const inputType = (isPassword) => (isPassword ? "text" : "password");

  return (
    <>
      <CssBaseline />
      <Container
        sx={{ height: "calc(100% - 50px)", width: "100%", position: "static" }}
      >
        <PageHeader title={t("resetPasswordTitle")} />
        <form
          id="forgot-password"
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
        >
          <FormControl>
            <FormLabel>{t("newPasswordLabel")} *</FormLabel>
            <Input
              sx={{ width: "100%", marginBottom: "10px" }}
              placeholder={t("newPasswordPlaceholder")}
              type={inputType(visibility)}
              required
              variant="plain"
              startDecorator={<Key />}
              endDecorator={visibilityIconRenderer(visibility)}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              size="md"
            />
          </FormControl>

          <FormControl>
            <FormLabel>{t("confirmPasswordLabel")} *</FormLabel>
            <Input
              sx={{ width: "100%", marginBottom: "10px" }}
              placeholder={t("confirmPasswordPlaceholder")}
              type={inputType(visibility)}
              required
              variant="plain"
              startDecorator={<Key />}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              size="md"
              endDecorator={visibilityIconRenderer(visibility)}
            />
          </FormControl>
          {status === "failure" && (
            <FormHelperText
              sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
            >
              {te("genericError")}
            </FormHelperText>
          )}

          {errors.confirmPassword && (
            <FormHelperText
              sx={(theme) => ({
                color: theme.vars.palette.primary[400],
              })}
            >
              {errors.confirmPassword}
            </FormHelperText>
          )}

          <Button
            sx={{ width: "100%", marginTop: "20px" }}
            variant="solid"
            color="primary"
            size="md"
            loading={status === "loading"}
            disabled={errors.confirmPassword ? true : false}
            type="submit"
          >
            {t("sendButton")}
          </Button>
        </form>
      </Container>
    </>
  );
};

export default ResetPassword;
