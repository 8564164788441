const eventType = import.meta.env.VITE_EVENT_TYPE;
const region = import.meta.env.VITE_REGION;
const sentryKey = import.meta.env.VITE_SENTRY_KEY;
const env = import.meta.env.VITE_ENV;

import * as Sentry from "@sentry/react";

export const initSentryOptions = {
    environment: env || "development",
    dsn: sentryKey,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    profilesSampleRate: 0.25,
    tracePropagationTargets: [
        "localhost",
        `https://${eventType}.dev.cloudofmemories.${region}`,
        "https://family.dev.cloudofmemories.ro",
        `https://${eventType}.cloudofmemories.${region}`,
    ],
};
