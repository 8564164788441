import { Typography } from "@mui/joy";
import Container from "@mui/material/Container";

import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createRemindMeLaterForUser, updateUserById } from "../../api/userApi";
import NetworkWrapper from "../components/common/NetworkWrapper";
import EventData from "../components/eventPage/EventData";
import EventDetails from "../components/eventPage/EventDetails";
import EventUser from "../components/eventPage/EventUser";
import RemindMeLaterModal from "../components/eventPage/RemindMeLaterModal";
import useQuery from "../hooks/useQuery";
import AppPageHeader from "../components/common/AppPageHeader";
import { getLocalStorageUser, updateLocalUser } from "../utils/helpers";

const EventPage = () => {
  const [eventData, userData, isUserAdmin, getUser] = useOutletContext();

  const [modalRemindMeLater, setModalRemindMeLater] = useState(false);
  const [firstTimeEmail, setFirstTimeEmail] = useState("");

  const query = useQuery();
  const navigate = useNavigate();

  const updateUser = async (id, displayName, email, setErrorMessage) => {
    const data = { id };
    if (displayName !== null) data.displayName = displayName;
    if (email !== null) data.email = email;

    try {
      const response = await updateUserById(data);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorMessage("Email deja folosit!");
        } else if (error.response.status === 400) {
          return;
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          setErrorMessage("Ceva nu a mers bine.");
        }
      } else {
        console.error(error);
      }
    }
  };

  const createRmlForUser = async (user, setErrorMessage) => {
    setIsLoaded(false);
    const payload = {
      id: user._id,
      email: user.email,
    };

    try {
      const data = await createRemindMeLaterForUser(payload);
      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        // setUserData(data);
        setModalRemindMeLater(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorMessage("Email deja folosit!");
        } else if (error.response.status === 400) {
          return;
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          setErrorMessage("Ceva nu a mers bine.");
        }
      } else {
        console.error(error);
        throw new Error("Ceva nu a mers bine.");
      }
    }
  };

  const remindMeLater = async (fromModal, setErrorMessage) => {
    try {
      const user = getLocalStorageUser();
      if (fromModal) {
        const updateResponse = await updateUser(
          user._id,
          null,
          firstTimeEmail,
          setErrorMessage
        );
        if (updateResponse) {
          updateLocalUser(null, firstTimeEmail);
          getUser();
          user.email = firstTimeEmail;
          await createRmlForUser(user, setErrorMessage);
        }
      } else if (!user.email || user.email.length === 0) {
        setModalRemindMeLater(true);
      } else {
        await createRmlForUser(user, setErrorMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserDisplayName = async (displayName) => {
    try {
      const user = getLocalStorageUser();
      await updateUser(user._id, displayName, null);
      updateLocalUser(displayName, null);
      getUser();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AppPageHeader />
      <Container>
        <NetworkWrapper>
          {eventData != null && (
            <>
              <Typography
                level="p"
                fontWeight="lg"
                variant="soft"
                sx={{ marginTop: "10px", backgroundColor: "transparent" }}
              >
                {eventData.welcomeMessage}
              </Typography>

              <EventUser
                userData={userData}
                eventData={eventData}
                isUserAdmin={isUserAdmin}
                updateUserDisplayName={updateUserDisplayName}
                query={query}
                remindMeLater={remindMeLater}
                navigate={navigate}
              />

              <EventData
                event={eventData}
                isAdmin={isUserAdmin}
                isDemoAdmin={userData?._id == "64b7ca4d8565dfcd3d2cdeca"}
              />

              <EventDetails
                isUserAdmin={isUserAdmin}
                userData={userData}
                eventData={eventData}
              />

              {/* Spacer */}
              <div style={{ height: "100px" }}></div>

              {/* Modals */}
              <RemindMeLaterModal
                modalRemindMeLater={modalRemindMeLater}
                setModalRemindMeLater={setModalRemindMeLater}
                firstTimeEmail={firstTimeEmail}
                setFirstTimeEmail={setFirstTimeEmail}
                remindMeLater={remindMeLater}
              />
            </>
          )}
        </NetworkWrapper>
      </Container>
    </>
  );
};

export default EventPage;
