// ** React Imports
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// ** Hooks imports
import useQuery from "../hooks/useQuery";

// ** Helpers Imports
import { getQRCode } from "../utils";

// ** Images imports
import Logo from "../assets/logo.png";

// ** Api Imports
import { getEventData } from "../../api/eventApi";

const backEndUrl = import.meta.env.VITE_BACKEND_URL;
const region = import.meta.env.VITE_REGION;

const defaultQrCode = getQRCode(`${backEndUrl}${region}/demo`);

const useCatalog = () => {
  const { t } = useTranslation("CatalogPage");

  const defaultGroomsName = t("defaultGroomsName");
  const defaultDescription = t("defaultCardText");

  const [catalogData, setCatalogData] = useState({
    groomsName: defaultGroomsName,
    description: defaultDescription,
    logo: Logo,
    qrCodeImage: defaultQrCode,
  });
  const [isEventDemo, setIsEventDemo] = useState(true);
  const [refetchData, setRefetchData] = useState(true);

  const refetchCatalogData = () => setRefetchData((prev) => !prev);

  const query = useQuery();

  const getCatalogData = async () => {
    const eventId = query.get("event");

    if (!eventId) return;

    const qrCodeImg = getQRCode(
      `${backEndUrl}${region}/event?event=${eventId}`
    );
    try {
      const eventData = await getEventData(eventId);
      let { cardTitle, cardText, isDemo } = eventData;
      setIsEventDemo(isDemo);

      cardTitle = cardTitle || data.name;
      cardText = cardText || defaultDescription;

      const cardData = {
        groomsName: cardTitle,
        description: cardText,
        logo: Logo,
        qrCodeImage: qrCodeImg,
      };

      Sentry.setContext("catalogData", cardData);

      setCatalogData(cardData);
    } catch (error) {
      Sentry.withScope(function (scope) {
        scope.setLevel("warning");
        scope.setFingerprint(["useCatalog.js", "getCatalogData"]);
        Sentry.captureException(error);
      });
    }
  };

  useEffect(() => {
    getCatalogData();
  }, [refetchData]);

  return { catalogData, isEventDemo, refetchCatalogData };
};

export default useCatalog;
