import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createGuestUser } from "../../api/userApi";
import useEventBlock from "./useEventBlock";
import { ErrorTypes } from "../utils/helpers";
import { getLocalStorageToken, getLocalStorageUser } from "../utils/helpers";
import * as Sentry from "@sentry/react";

export function useUserData({ eventData }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [, setErrorType] = useEventBlock();

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoaded(false);
    getUser();
  }, [eventData]);

  const getUser = async () => {
    try {
      if (eventData && !userData) {
        const storageUser = getLocalStorageUser();
        const token = getLocalStorageToken();

        // No user exists, simply create a new one
        if (!storageUser) {
          const newUser = await createGuestUser(eventData);
          Sentry.setContext("user", newUser);

          setUserData(newUser);
          setIsUserAdmin(false);
          setIsLoaded(false);
        } else {
          let definedUser = null;

          //Check validity API & log admin user
          if (token && storageUser.event?._id === eventData._id) {
            setIsUserAdmin(true);
            setUserData(storageUser);
            setIsLoaded(true);
          } else {
            setIsUserAdmin(false);

            // Create new user if the user is from DEMO
            if (!eventData.isDemo && storageUser.isDemoUser) {
              definedUser = await createGuestUser(eventData);
            }

            // Create guest user with imported data from another event
            else if (
              !eventData.demoId &&
              !storageUser.isDemoUser &&
              storageUser.event?._id !== eventData?._id
            ) {
              definedUser = await createGuestUser(
                eventData,
                storageUser.displayName
              );
            }

            //Check if user is demo user
            else if (
              eventData.demoId &&
              storageUser._id === "64b7ca4d8565dfcd3d2cdeca"
            ) {
              definedUser = storageUser;
              // this user is a admin for the demo
              setIsUserAdmin(true);
            }

            //Create a guest user for demo event
            else if (
              eventData.demoId &&
              storageUser.event?._id !== eventData._id
            ) {
              definedUser = await createGuestUser(eventData);
            }

            // Check if the user is the same for this event
            else if (storageUser.event?._id === eventData?._id) {
              definedUser = storageUser;
            }

            // SET the correct user if that exists on the above verifications
            if (definedUser) {
              Sentry.setContext("user", definedUser);
              setUserData(definedUser);
              setIsLoaded(true);
            } else {
              localStorage.clear();
              setIsLoaded(true);
              navigate("/");
            }
          }
        }
      } else if (eventData && userData) {
        const storageUser = getLocalStorageUser();

        if (JSON.stringify(storageUser) !== JSON.stringify(userData)) {
          setUserData(storageUser);
          Sentry.setContext("user", storageUser);
        }
      }
    } catch (error) {
      sessionStorage.setItem("isTutorialDone", false);
      const message = error.response?.data?.message;
      if (message === "Max guests reached!")
        setErrorType(ErrorTypes.EventLimit);
      if (message === "Cannot create user because the event has already ended")
        setErrorType(ErrorTypes.EventOverUser);

      Sentry.withScope(function (scope) {
        scope.setTag("isUserAdmin", isUserAdmin);
        scope.setLevel("warning");
        scope.setFingerprint(["useUserData.js", "getUser"]);
        Sentry.captureException(error);
      });
    }
  };

  return [userData, isUserAdmin, isLoaded, getUser];
}
