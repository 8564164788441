import axios from "axios";
import * as Sentry from "@sentry/react";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

const createUser = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/users`, payload);
    return response.data;

  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const createGuestUser = async (eventData, displayName) => {
  const currentDate = new Date();
  const payload = {
    displayName: displayName ? displayName : "Anonim" + currentDate.getTime(),
    eventId: eventData._id,
    isDemoUser: eventData.demoId ? true : false,
  };
  try {
    const user = await createUser(payload);

    localStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("isTutorialDone", false);
    localStorage.removeItem("token");
    return user;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setTag("eventData", eventData)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getUserUsedEmail = async (email, paymentId) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/users/verifyEmailAndPayment?email=${email}&ptk=${paymentId}`
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("email", email)
      scope.setTag("paymentId", paymentId)
      scope.setLevel("warning")
      scope.setFingerprint(["GET", "/api/users/verifyEmailAndPayment", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const createNewAdminAccount = async (payload) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/users/admin`, payload);
    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users/admin", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const subscribeNewsLetter = async (eventId, payload) => {
  try {
    await axios.post(`${BASE_URL}/api/users/subscriber/${eventId}`, payload);

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("debug")
      scope.setFingerprint(["POST", "/api/users/subscriber", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
  }
};

export const adminLogin = async (payload) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/users/admin/login`,
      payload
    );

    const { token, user } = data;

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users/admin/login", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getDemoUser = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/users/admin/getDemoUser`);

    const token = data.token;

    localStorage.setItem("token", token);

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning")
      scope.setFingerprint(["GET", "/api/users/admin/getDemoUser", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const updatePassword = async (payload) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/users/forgotPassword`,
      payload
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users/forgotPassword", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const updateUserById = async (payload) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/api/users`, payload);

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["PUT", "/api/users", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const createRemindMeLaterForUser = async (payload) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/users/remindMeLater`,
      payload
    );
    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", "/api/users/remindMeLater", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const sendResetPasswordEmail = async (payload, token) => {
  try {
    const { data } = await axios.put(
      `${BASE_URL}/api/users/resetPassword`,
      payload,
      token && {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["PUT", "/api/users/resetPassword", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};
