import axios from "axios";
import * as Sentry from "@sentry/react";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const createWish = async (payload) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/wishes`, payload);
    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload)
      scope.setLevel("warning")
      scope.setFingerprint(["POST", '/api/wishes', String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getWishes = async (eventId, userId) => {
  try {
    const token = localStorage.getItem("token");

    let apiUrl = `${BASE_URL}/api/wishes/event/${eventId}`;
    if (!token) {
      apiUrl += `/user/${userId}`;
    }

    const { data } = await axios.get(
      apiUrl,
      token && { headers: { Authorization: token } }
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("userId", userId)
      scope.setLevel("warning")
      scope.setFingerprint(["GET", '/api/wishes', String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};
