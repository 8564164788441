// ** React Imports

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";

// ** MUI IMports
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/joy/Typography/Typography";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";

// ** Component Imports
import WishCard from "../components/WishCard";
import NetworkWrapper from "../components/common/NetworkWrapper";
import Loading from "../components/common/Loading";
import AppPageHeader from "../components/common/AppPageHeader";
import AddMessage from "../components/AddMessage";

// ** Helpers Imports
import { getLocalStorageToken, updateLocalUser } from "../utils/helpers";

// ** Config Imports
import { styles, texts } from "../config/index";

// ** Hooks Imports
import useNetwork from "../hooks/useNetwork";
import useQuery from "../hooks/useQuery";

// ** Api imports
import { createWish, getWishes } from "../../api/wishApi";
import { updateUserById } from "../../api/userApi";

const MessagesPage = () => {
  //Hooks
  const [eventData, userData, , getUser] = useOutletContext();
  const messageContainerRef = useRef(null);
  const query = useQuery();
  const navigate = useNavigate();
  const { networkState } = useNetwork();
  const { t } = useTranslation("MessagesPage");

  // States
  const [messages, setMessages] = useState(null);
  const [message, setMessage] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [firstTimeName, setFirstTimeName] = useState("");
  const [firstTimeEmail, setFirstTimeEmail] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emptyTypeDataError, setEmptyTypeDataError] = useState(false);

  // Variables
  const { demoMessages } = texts;

  const handleMessagesError = (error) => {
    const isAuthError =
      error.response?.data?.message === "Autentificare incorecta";

    if (isAuthError) {
      if (userData._id === "64b7ca4d8565dfcd3d2cdeca") {
        navigate("/demo");
      } else {
        navigate("/admin-login");
      }
    } else {
      console.error("Error fetching messages:", error);
    }

    setMessages([]);
  };

  const getMessages = async () => {
    setIsLoaded(false);

    try {
      const eventId = query.get("event");

      const wishesData = await getWishes(eventId, userData._id);

      const messages = eventData.isDemo
        ? [...demoMessages, ...wishesData]
        : [...wishesData];

      setMessages(messages);
    } catch (error) {
      handleMessagesError(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const updateUser = async (id, displayName, email) => {
    try {
      let data = { id };

      if (displayName) data.displayName = displayName;
      if (email) data.email = email;

      await updateUserById(data);
    } catch (error) {
      console.log("Error updating user:", error);
    }
  };

  const addWish = async (userId, message, eventId) => {
    const trimmedMessage = message.trim();

    if (trimmedMessage === "") {
      setEmptyTypeDataError(true);
      return;
    } else {
      setEmptyTypeDataError(false);
    }

    const wishPayload = {
      user: userId,
      text: trimmedMessage,
      event: eventId,
    };
    const wishResponse = await createWish(wishPayload);

    return wishResponse;
  };

  const scrollToMessageEnd = (messageContainerRef) => {
    setTimeout(() => {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 200);
  };

  const handleAddMessageSubmit = async (event, fromModal) => {
    event.preventDefault();

    setMessageStatus("loading");
    try {
      if (fromModal) {
        if (firstTimeName.trim() !== "") {
          await updateUser(userData._id, firstTimeName, firstTimeEmail);
          updateLocalUser(firstTimeName, firstTimeEmail);
          getUser();
        }
        await addWish(userData._id, message, eventData._id);

        getUser();
        setMessage("");
      } else if (
        !userData.displayName ||
        userData.displayName?.includes("Anonim")
      ) {
        setModalOpen(true);
        return;
      } else {
        await addWish(userData._id, message, eventData._id);
        setMessage("");
      }

      getMessages();
      setModalOpen(false);
      scrollToMessageEnd(messageContainerRef);
      setMessageStatus("");
    } catch (error) {
      setMessageStatus("failure");
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData) {
      getMessages();
    }
  }, [userData]);

  useEffect(() => {
    isLoaded &&
      setTimeout(() => {
        messageContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 200);
  }, [isLoaded]);

  return (
    <>
      <Loading isLoaded={isLoaded} />
      <AppPageHeader
        showImageHeader={false}
        pageHeaderProps={{
          title: texts.messages.multipleTypesOfMessage.capitalized(),
          body: t("pageSubtitle"),
        }}
      />
      <Container>
        <Box
          ref={messageContainerRef}
          sx={{
            width: "100%",
            height: "calc(100% - 150px)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <NetworkWrapper>
            {eventData && messages !== null && (
              <>
                {isLoaded && messages?.length == 0 ? (
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography level="h5" style={{ textAlign: "center" }}>
                      {t("noMessagesInfo")}{" "}
                      {import.meta.env.VITE_REGION === "com"
                        ? texts.messages.multipleTypesOfMessage
                        : texts.messages.genderTypeOfMessage === "male"
                        ? "un " + texts.messages.singleTypeOfMessage
                        : "o " + texts.messages.singleTypeOfMessage}{" "}
                      {t("noMessagesInfo2")}
                      <br />
                      <span
                        style={{
                          fontWeight: "800",
                          color: styles.primaryColor,
                        }}
                      >
                        {t("beFirstMessageCallToAction")}{" "}
                        {import.meta.env.VITE_REGION === "com"
                          ? texts.messages.multipleTypesOfMessage
                          : texts.messages.genderTypeOfMessage === "male"
                          ? "un " + texts.messages.singleTypeOfMessage
                          : "o " + texts.messages.singleTypeOfMessage}
                        !
                      </span>
                    </Typography>
                  </div>
                ) : (
                  <>
                    {messages.map((message, index) => {
                      return <WishCard message={message} key={index} />;
                    })}
                    <div style={{ height: "170px" }}></div>
                  </>
                )}
              </>
            )}
          </NetworkWrapper>
        </Box>
        <Modal
          open={modalOpen}
          onClose={() => {
            setMessageStatus("");
            setModalOpen(false);
          }}
        >
          <ModalDialog sx={{ width: 350, bgcolor: "background.paper" }}>
            <ModalClose
              sx={{ width: 20, height: 20, color: "black!important" }}
            />
            <Typography level="h4">
              {texts.messages.singleTypeOfMessage.capitalized()}
            </Typography>
            <form onSubmit={(event) => handleSubmit(event, true)}>
              <FormControl sx={{ mb: 2 }}>
                <FormLabel>
                  {texts.messages.addMessage.capitalized()} *
                </FormLabel>
                <Input
                  fullWidth
                  label="Message"
                  name="message"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  variant="outlined"
                  margin="normal"
                />
              </FormControl>
              <FormControl sx={{ mb: 2 }}>
                <FormLabel>{t("modalNameLabel")}</FormLabel>
                <Input
                  fullWidth
                  label="Name"
                  name="name"
                  value={firstTimeName}
                  onChange={(event) => setFirstTimeName(event.target.value)}
                  variant="outlined"
                  margin="normal"
                />
              </FormControl>
              {!userData?.email && (
                <FormControl sx={{ mb: 2 }}>
                  <FormLabel>{t("modalEmailLabel")}</FormLabel>
                  <Input
                    fullWidth
                    label="Email"
                    name="email"
                    value={firstTimeEmail}
                    onChange={(event) => setFirstTimeEmail(event.target.value)}
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
              )}
              <Button
                variant="contained"
                onClick={(event) => {
                  handleAddMessageSubmit(event, true);
                }}
              >
                {t("sendMessageButton")} {texts.messages.singleTypeOfMessage}!
              </Button>
            </form>
          </ModalDialog>
        </Modal>
        {networkState?.online && eventData && (
          <>
            <AddMessage
              handleSubmit={handleAddMessageSubmit}
              message={message}
              setMessage={setMessage}
              messageStatus={messageStatus}
              emptyTypeDataError={emptyTypeDataError}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default MessagesPage;
