// ** React Imports
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// ** MUI Imports
import Email from "@mui/icons-material/Email";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

// ** Component Imports
import PageHeader from "../components/PageHeader";

// ** Styles Imports
import { styles } from "../config";

// ** Api imports
import { updatePassword } from "../../api/userApi";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [status, setStatus] = useState("initial");

  const { t } = useTranslation("ForgotPassword");
  const { t: te } = useTranslation("Errors");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try {
      const payload = { email };
      await updatePassword(payload);

      setStatus("sent");
    } catch (e) {
      setStatus("failure");
    }
  };

  return (
    <>
      <CssBaseline />
      <Container
        sx={{ height: "calc(100% - 50px)", width: "100%", position: "static" }}
      >
        <PageHeader title={t("passwordResetTitle")} />
        {(status === "initial" || status === "failure") && (
          <form
            id="forgot-password"
            style={{ width: "100%" }}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <FormLabel>{t("emailLabel")} *</FormLabel>
              <Input
                sx={{ width: "100%", marginBottom: "10px" }}
                placeholder={t("emailPlaceholder")}
                type="email"
                required
                variant="plain"
                startDecorator={<Email />}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                size="md"
              />
            </FormControl>
            {status === "failure" && (
              <FormHelperText
                sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
              >
                {te("emailNotExists")}
              </FormHelperText>
            )}

            <Button
              sx={{ width: "100%" }}
              variant="solid"
              color="primary"
              size="md"
              loading={status === "loading"}
              type="submit"
            >
              {t("buttonText")}
            </Button>
          </form>
        )}

        {status === "sent" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: "10px",
            }}
          >
            <FormHelperText
              sx={(theme) => ({
                color: theme.vars.palette.primary[400],
                fontWeight: 500,
                fontSize: "17px",
                marginBottom: "10px",
              })}
            >
              {t("emailSentConfirmation")} {email}
            </FormHelperText>
            <Box sx={{ display: "flex", marginBottom: "10px" }}>
              <Typography sx={{ color: "black !important" }}>
                {t("emailNotReceived")}&nbsp;
              </Typography>
              <Typography
                textColor={styles.primaryColor}
                sx={{
                  fontWeight: 700,
                  cursor: "pointer",
                }}
                onClick={() => setStatus("initial")}
              >
                {t("tryAgain")}
              </Typography>
            </Box>
            <Typography sx={{ color: "black" }}>{t("checkSpam")}</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ForgotPassword;
