import axios from "axios";
import * as Sentry from "@sentry/react";
import { getLocalStorageToken } from "../src/utils/helpers";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const validateEventById = async (eventId) => {
  const response = await axios.get(
    `${BASE_URL}/api/events/validate/${eventId}`
  );
  return response.data;
};

export const getGuestUserPhotos = async (eventId, userId) => {
  const path = "/api/images/event/" + eventId + "/user-guest/" + userId;
  try {
    const response = await axios.get(BASE_URL + path);

    // Reverse to have them last in first out
    return response.data.reverse();
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint(["GET", path, String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getAllImages = async (eventId, userId, page) => {
  const token = getLocalStorageToken();

  const config = token
    ? {
        headers: {
          Authorization: token,
        },
      }
    : {};

  try {
    const response = await axios.get(
      `${BASE_URL}/api/images/event/${eventId}${
        !token ? "/user/" + userId : ""
      }?page=${page}`,
      config
    );
    const images = response.data.images;
    if (!Array.isArray(images)) {
      throw "Images missing";
    }
    return response.data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint([
        "GET",
        "/api/images/event/",
        String(error?.response?.status),
      ]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getEventData = async (eventId) => {
  const path = "/api/events/" + eventId;
  try {
    const response = await axios.get(BASE_URL + path);
    return response.data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint(["GET", path, String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const startDownload = async (eventId) => {
  const token = getLocalStorageToken();
  const path = "/api/archive/start/event/" + eventId;
  try {
    const response = await axios.get(BASE_URL + path, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint(["GET", path, String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getArchiveStatus = async (eventId) => {
  const path = "/api/archive/status/event/" + eventId;
  try {
    const response = await axios.get(BASE_URL + path);
    return response.data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint(["GET", path, String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const getDateSortedEvents = async (userId) => {
  const path = `/api/events/user/${userId}`;
  try {
    const { data: events } = await axios.get(`${BASE_URL}${path}`);
    const sortedEvents = events.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB; // Change to 'dateB - dateA' for descending order
    });

    return sortedEvents;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      scope.setFingerprint(["GET", path, String(error?.response?.status)]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const archiveLink = (eventId) => {
  return BASE_URL + "/api/archive/event/" + eventId + "/media";
};

export const updateEventOnUser = async (eventId, userId) => {
  try {
    const { data: userData } = await axios.put(`${BASE_URL}/api/users`, {
      eventId,
      id: userId,
    });
    return userData;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("userId", userId);
      scope.setLevel("warning");
      scope.setFingerprint([
        "PUT",
        "/api/users",
        String(error?.response?.status),
      ]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const createNewEvent = async (payload, token) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/events`, payload, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload);
      scope.setLevel("warning");
      scope.setFingerprint([
        "POST",
        "/api/events",
        String(error?.response?.status),
      ]);
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const updateEventImage = async (payload, eventId) => {
  try {
    const token = localStorage.getItem("token");

    const { data } = await axios.put(
      `${BASE_URL}/api/events/image/${eventId}`,
      payload,
      token && {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payload", payload);
      scope.setLevel("warning");
      scope.setFingerprint([
        "PUT",
        "/api/events/image",
        String(error?.response?.status),
      ]);
      Sentry.captureException(error);
    });
    throw error;
  }
};
