import axios from "axios";
import * as Sentry from "@sentry/react";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const getPaymentByTk = async (tk) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/payments/payment/${tk}`);
    return response.data;

  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("payment_token", tk)
      scope.setLevel("warning")
      scope.setFingerprint(["GET", "/api/payments/payment", String(error?.response?.status)]);
      Sentry.captureException(error);
    });
  }
};
